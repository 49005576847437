<template>
   
    <!-- <div id="advancedSearch"  class="text-end insideGraph">
        <div class="advancedMenu">
            <a href="javascript:void(0)" class="selectDropdown f-14 min-auto bg-white d-flex align-items-center justify-content-between" @click="trading = !trading" style="width:210px">{{tradinfFilter}}<i class="ms-2 fa fa-caret-down" aria-hidden="true"></i></a>
            <ul class="dropdown_menu_animated week py-2" :class="[{'show':trading}]">
                <li v-for="value,key in static_vars.tradingPairsFilterJson" :key="key">
                    <a @click="tradinfFilter=value;trading=false;getTradingData()" :class="[{'active':tradinfFilter == value}]" href="javascript:void(0)">{{value}}</a>
                </li>
            </ul>
        </div>
    </div> -->
     <div id="tradingPairsChart" class="tradingPairsChart" style="height:250px"  v-show="Object.keys(store.tradingPairsChart).length && store.tradingPairsChart.series && store.tradingPairsChart.series.length && store.tradingPairsChart.series[0].data.length"></div>
     <Nodata v-if="!store.singleLoading && Object.keys(store.tradingPairsChart).length && store.tradingPairsChart.series && store.tradingPairsChart.series.length && store.tradingPairsChart.series[0].data.length == 0"></Nodata>
</template>

<script>
    import Highcharts from 'highcharts';
// import * as am5 from "@amcharts/amcharts5";
// import * as am5percent from "@amcharts/amcharts5/percent";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {    
        return {
            tradinfFilter : 'Pairs',
            trading : false
        }
    },
     watch : {
        '$route.params.id'() {
            this.getTradingData()
        },
        '$route.query.t'() {
            this.getTradingData()
        },
        // '$parent.$parent.tradingprofitTab'(){
        //     this.getTradingData()
        // }
    },
    methods: {
        drawTradingChartData(){
            // let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'tradingPairsChart');
            // d?.dispose();
            
            // let root = am5.Root.new("tradingPairsChart", {
            //   useSafeResolution: false
            // });
            
            // root.setThemes([
            //     am5themes_Animated.new(root),
            //     // am5themes_Responsive.new(root)
            // ]);

            // // Create chart
            // var chart = root.container.children.push(am5percent.PieChart.new(root, {
            //     layout: root.verticalLayout
            // }));

            // var series = chart.series.push(am5percent.PieSeries.new(root, {
            //     valueField: "y",
            //     categoryField: "x",
            // }));
            // series.get("colors").set("colors", [
            //     am5.color(0x4572a7),
            //     am5.color(0xCBCBCB),
            //     am5.color(0x69cd4b),
            //     am5.color(0xff6f34),
            //     am5.color(0xf6f819),
            //     am5.color(0x7dfe8b),
            //     am5.color(0xffaf6e),
            //     am5.color(0xfff263),
            //     am5.color(0xff7200),
            // ]);
            // series.data.setAll(this.store.tradingPairsChart.series[0].data);
            // series.slices.template.set("tooltipText", "{x}: {y}");
            // series.slices.template.set("toggleKey", "none");
            // series.labels.template.setAll({
            //     text: "{x}",
            //     radius: 10,
            //     fontSize: 12,
            //     fontWeight: "600",
            // });
            // series.appear(1000, 100);
            let chartOptions = {
                title: {
                    text:''
                },
                chart: {
                    type: 'pie',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    // borderWidth:0,
                    // spacingLeft: 0,
                    // spacingRight: 0
                    backgroundColor: 'var(--bodycolor)'
                },
                credits:{
                    enabled: false,
                },
                labels:{
                    style: {
                        borderColor: "var(--bodycolor)",
                        borderWidth:0,
                        textShadow: 'none',
                        fontSize: '22px'
                    }
                },
                yAxis:{
                    title: {
                        text: "Trades",
                        // style:{
                        //     color:"var(--title)",
                        //     borderWidth:0
                        // }
                    },
                    
                    labels:{
                        // style: {
                        //    color:"var(--title)",
                        //    borderWidth:0
                        // }
                    },
                    borderWidth:0
                    // categories:[]
                    // lineColor: "transparent"
                },
                xAxis: {
                    labels:{
                        name: 'Trades',
                        // style: {
                        //     textOverflow: 'none',
                        //     fontWeight: 'medium',
                        //     color:"var(--title)",
                        //     // borderColor: "#1b1b1b",
                        //     borderWidth:0
                        //     // fontSize: '20px',
                        // }
                    },
                    // color: 'var(--bodycolor)',
                    categories:[]
                    // visible:false,
                    // showFirstLabel: false,
                    // showLastLabel: false,
                    // type: 'categories',
                },
                
                plotOptions: {
                     pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        borderWidth: 0,
                        borderColor: 'var(--bodycolor)',
                        textShadow: 'none',
                        dataLabels: {
                            enabled: true,
                            borderWidth: 0,
                            borderColor: 'var(--bodycolor)',
                            textShadow: 'none',
                            style: {
                                borderColor: "var(--bodycolor)",
                                borderWidth:0,
                                textShadow: 'none',
                                textOutline: 'none',
                                color: "var(--dark)"
                            },
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }

                },
                series: []
            };

            this.store.tradingPairsChart.series.forEach((val) =>{
                if(val.data.length){
                    var db = []
                    for (var i = val.data.length - 1; i >= 0; i--) {

                        if(i==0){
                            db.push({
                                name: val.data[i].x,
                                y: val.data[i].y,
                                sliced: true,
                                selected: true
                            })
                            
                        }else{
                            db.push({
                                name: val.data[i].x,
                                y: val.data[i].y
                            })
                        }
                        
                        
                    }
                    let temp = {
                        showInLegend: false,
                        type: 'pie',
                        name: 'Trades',
                        data: db,
                        borderWidth: 0
                    }
                    chartOptions.series.push(temp);
                    
                }
            })
            

            Highcharts.setOptions({
                lang: {
                    decimalPoint: ".",
                    thousandsSep: ",",
                    decimals: 2
                }
            });

            Highcharts.chart('tradingPairsChart', chartOptions);
            
        },
        getTradingData(){
            let formData = {};
            let type = ''
            if(this.tradinfFilter == 'Pairs'){
                type = 'CURRENCY_COUNT'
            }else {
                type = 'CURRENCY_TIMEZONE'
            }
            this.store.callTradingPairsChartData(formData,true,this.$route.params.id,this.$route.query.t,type).then(() =>{
                if(Object.keys(this.store.tradingPairsChart).length && this.store.tradingPairsChart.series && this.store.tradingPairsChart.series.length > 0)
                    // am5.ready(() => {
                        this.drawTradingChartData()
                    // });
            })
        }
    },
    mounted() {
        this.getTradingData()
    },
}
</script>
<style>
    .tradingPairsChart{
        height: 300px;
        width: 100%;
    }
</style>