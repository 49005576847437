<template>
    <div id="drawdown" class="drawdown"  style="height:300px" v-show="Object.keys(store.tradingDrawDownChart).length && store.tradingDrawDownChart.series && store.tradingDrawDownChart.series.length && store.tradingDrawDownChart.series[0].data.length"></div>
     <Nodata v-if="!store.loader && Object.keys(store.tradingDrawDownChart).length && store.tradingDrawDownChart.series && store.tradingDrawDownChart.series.length && store.tradingDrawDownChart.series[0].data.length == 0"></Nodata>
</template>

<script>
// import * as am5 from "@amcharts/amcharts5";
// import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
 import Highcharts from 'highcharts';
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            drawdownJSon : {
                'DRAWDOWN_DAILY_MONEY' : 'Daily Drawdown',
                'CLOSED_PROFIT_MONEY' : 'Closed Trades Only',
                'TOTAL_PROFIT_MONEY' : 'Profit',
                'DRAWDOWN_DAILY' : 'Daily Drawdown',
                'CLOSED_PROFIT' : 'Closed Trades Only',
                'TOTAL_PROFIT' : 'Profit'
            }
        }
    },
    watch : {
        '$route.params.id'() {
            this.getTrendingDrawDownChartData()
        },
        '$route.query.t'() {
            this.getTrendingDrawDownChartData()
        },
        // '$parent.$parent.tradingprofitTab'(){
        //     this.getTrendingDrawDownChartData()
        // }
    },
    methods: {
        drawdownChart(){
            // let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'drawdown');
            // d?.dispose();
            
            // let root = am5.Root.new("drawdown", {
            //   useSafeResolution: false
            // });


            // root.interfaceColors.set("grid", am5.color(0xffffff));
            // root.setThemes([
            //     am5themes_Animated.new(root),
            //     am5themes_Responsive.new(root)
            // ]);
            // root.dateFormatter.setAll({
            //     dateFormat: "yyyy",
            //     dateFields: ["valueX"]
            // });
            // root.numberFormatter.setAll({
            //   numberFormat: "#.###",
            //   smallNumberThreshold: 0.01
            // });


            // // Create chart
            // // https://www.amcharts.com/docs/v5/charts/xy-chart/
            // var chart = root.container.children.push(am5xy.XYChart.new(root, {
            //      focusable: true,
            //     panX: false,
            //     panY: false,
            //     wheelX: "panX",
            //     wheelY: "zoomX",
            //      pinchZoomX:true,
            //     draggable:false
            // }));

            // chart.get("colors").set("colors", [
            //     am5.color(0x50b432),
            //     am5.color(0x4572a7),
            //     am5.color(0xff7200),
            // ]);


            // // Add cursor
            // var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            //     behavior: "none"
            // }));
            // cursor.lineY.set("visible", false);
            // cursor.lineX.set("visible", false);
            // var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            //   maxDeviation: 0,
            //   baseInterval: {
            //     timeUnit: "day",
            //     count: 1
            //   },
            //   renderer: am5xy.AxisRendererX.new(root, {}),
            //   tooltip: am5.Tooltip.new(root, {}),
            //     startLocation: 1,
            //     endLocation: 1
            // }));

            // // var label = am5.Label.new(root, {
            // //     text: "[#666] Date [/]",
            // //     x: am5.p50,
            // //     centerY: am5.p50,
            // //     fontSize: 12,
            // // })
            // // xAxis.children.push( label );

            // var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            //   extraMin: 0.2,
            //   renderer: am5xy.AxisRendererY.new(root, {}),
            //   height: am5.percent(100),
            //   y: am5.percent(100),
            //   centerY: am5.percent(100),
            //   panY:false,
            // }));
            // // var label1 = am5.Label.new(root, {
            // //     rotation: -90,
            // //     text: this.$parent.$parent.tradingprofitTab=='Pips' ? 'Pips' : this.store.traderDetail.trader.stats.profile.baseCurrencyName,
            // //     fill: am5.color(0x666666),
            // //     y: am5.p50,
            // //     centerX: am5.p50,
            // //     fontSize: 12,
            // // })
            // // yAxis.children.unshift( label1 );
            //  let yRenderer = yAxis.get("renderer");
            // yRenderer.grid.template.setAll({
            //     stroke: am5.color(0x666666),
            //     strokeWidth: 1
            // });
            // var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
            //     x: am5.percent(50),
            //     centerX: am5.percent(50),
            //     layout: root.horizontalLayout,
            //     useDefaultMarker: true,
            //     // paddingTop: 15,
            // }));
            
            // legend.labels.template.setAll({
            //     fontSize: 12,
            //     fontWeight: "600"
            // });
            // legend.markerRectangles.template.setAll({
            //     cornerRadiusTL: 20,
            //     cornerRadiusTR: 20,
            //     cornerRadiusBL: 20,
            //     cornerRadiusBR: 20
            // });
            // legend.markers.template.setAll({
            //     width: 13,
            //     height: 13
            // });

            let chartOptions = {
                title: {
                    text:''
                },
                chart: {
                    type: 'line',
                    // spacingLeft: 0,
                    // spacingRight: 0
                    backgroundColor: 'var(--bodycolor)'
                    // spacing : [0, -6, 0, -6],
                },
                tooltip: {
                    pointFormat: '{series.name} {point.y:.2f}'
                },
                credits:{
                    enabled: false,
                    style:{
                        color:'#999999',
                        cursor:'pointer',
                        fontSize:'9px'
                    }
                },
                yAxis:{
                    title: {
                        text: "Profit",
                        style:{
                            color: 'var(--dark)',
                        }
                    },
                    labels:{
                        style: {
                            // fontSize: 18
                            color: 'var(--dark)',
                        }
                    },
                    // visible:false,
                    lineWidth: 0,
                    gridLineColor: "transparent",
                    lineColor: "transparent"
                },
                xAxis: {
                    labels:{
                        format: '{value:%b %y}',
                        autoRotation: [0,2],
                        style: {
                            textOverflow: 'none',
                            fontWeight: 'medium',
                            color: 'var(--dark)',
                            // fontSize: '20px',
                        }
                    },
                    lineWidth: 0,
                    gridLineColor: "transparent",
                    lineColor: "transparent",
                    // visible:false,
                    // showFirstLabel: false,
                    // showLastLabel: false,
                    type: 'datetime',
                },
                legend:{
                    borderWidth: 0,
                    itemStyle:{
                        color: 'var(--dark)'
                    }
                    
                },
                plotOptions: {
                    
                    series: {
                        color: 'var(--dark)'
                    },

                },
                series: []
            };
            this.store.tradingDrawDownChart.series.forEach((val, index) => {
                if(val.type == 'TOTAL_PROFIT_MONEY' || val.type == 'CLOSED_PROFIT_MONEY' || val.type == 'TOTAL_PROFIT' || val.type == 'CLOSED_PROFIT'){

                    let tooltipname = val.type == 'TOTAL_PROFIT_MONEY'? 'Profit': 'Closed Trades Only'

                    // const series = chart.series.push(am5xy.LineSeries.new(root, {
                    // //legendLabelText: this.drawdownJSon[val.type],
                    //   //name: "Series",
                    //   xAxis: xAxis,
                    //   yAxis: yAxis,
                    //   valueYField: "y",
                    //   valueXField: "x",
                    //   tooltip: am5.Tooltip.new(root, {
                    //     labelText: "[bold]"+tooltipname + "[/]{valueY}"
                    //   })
                    // }));
                    // series.strokes.template.setAll({
                    //     strokeWidth: 2
                    // });
                    // series.data.setAll(val.data);
                    let temp = {
                        selected: false,
                        showInLegend: true,
                        type: 'line',
                        name: tooltipname,
                        data:  val.data,
                        color: index==0 ?'var(--blue)':'var(--secondary)',
                        borderColor: index==0?'var(--blue)':'var(--secondary)',
                        borderWidth: 0
                    }
                    chartOptions.series.push(temp);

                    
                }else{
                    // var volumeAxisRenderer = am5xy.AxisRendererY.new(root, {});
                    // volumeAxisRenderer.grid.template.set("forceHidden", true);
                    // volumeAxisRenderer.labels.template.set("forceHidden", true);

                    // var volumeAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    //   height: am5.percent(100),
                    //   y: am5.percent(100),
                    //   centerY: am5.percent(100),
                    //   panY:false,
                    //   renderer: volumeAxisRenderer
                    // }));


                    // Add series
                    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
                    // var volumeSeries = chart.series.push(am5xy.ColumnSeries.new(root, {
                    //     //legendLabelText: this.drawdownJSon[val.type],
                    //   //name: "Volume Series",
                    //   xAxis: xAxis,
                    //   yAxis: yAxis,
                    //   valueYField: "y",
                    //   valueXField: "x",
                    //   tooltip: am5.Tooltip.new(root, {
                    //     labelText: "[bold]Daily Drawdown[/] {valueY}"
                    //   })
                    // }));

                    // volumeSeries.columns.template.setAll({ width: am5.percent(40) })
                    // volumeSeries.data.setAll(val.data);

                    let temp = {
                        selected: false,
                        showInLegend: false,
                        type: 'column',
                        name: "Daily Drawdown",
                        data:  val.data,
                        color: "#50b432"
                    }
                    chartOptions.series.push(temp);
                    //legend.data.unshift(volumeSeries);
                }
            })

            Highcharts.setOptions({
                lang: {
                    decimalPoint: ".",
                    thousandsSep: ",",
                    decimals: 2
                }
            });

            Highcharts.chart('drawdown', chartOptions);
            //  xAxis.get("renderer").labels.template.setAll({
            //     fill: root.interfaceColors.set("fill", am5.color("#666666")),
            //     fontSize: 12,
            // });
            // yAxis.get("renderer").labels.template.setAll({
            //     fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
            //     fontSize: 12,
            // });
            // //legend.data.setAll(chart.series.values);
            // chart.appear(1000, 100);
        },
        getTrendingDrawDownChartData(){
            let formData = {};
            // let type = '';
            // if(this.$parent.$parent.tradingprofitTab == this.store.traderDetail.trader.stats.profile.baseCurrencyName){
            //     type = 'TOTAL_PROFIT_MONEY,CLOSED_PROFIT_MONEY,DRAWDOWN_DAILY_MONEY'
            // }else{
            //     type = 'TOTAL_PROFIT,CLOSED_PROFIT,DRAWDOWN_DAILY'
            // }
            this.store.callTrendingDrawDownChartData(formData,true,this.$route.params.id,this.$route.query.t,'TOTAL_PROFIT_MONEY,CLOSED_PROFIT_MONEY,DRAWDOWN_DAILY_MONEY').then(() =>{
                 if(Object.keys(this.store.tradingDrawDownChart).length && this.store.tradingDrawDownChart.series && this.store.tradingDrawDownChart.series.length > 0)
                    // am5.ready(() => {
                        this.drawdownChart()
                    // });
            })
        }
    },
    mounted() {
        this.getTrendingDrawDownChartData()
    },
}
</script>
<style>
    .drawdown{
        height: 300px;
        width: 100%;
    }
</style>