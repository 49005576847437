<template>
    <section class=" customHeight"> 
        <div class="rightTradeChart position-relative">
            
            <div class="d-flex align-items-center justify-content-end" 
                style="margin-bottom: 16px;">  
                <div class="customdropdown pairOne position-relative advancedMenu currencypair">
                    <a href="javascript:void(0)" class="selectDropdown d-flex align-items-center justify-content-between f-14" @click="selectChart = !selectChart">
                        <span class="d-flex align-items-center">
                            {{ charttab ? tabJson[charttab] : 'Select Chart'}}</span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                    <ul class="dropdown_menu_animated week pb-2" :class="[{'show' : selectChart}]">  
                        <li :class="[{ 'active': charttab == 1 }]">
                            <a class="" href="javascript:void(0)" @click="changeChartTab(1)">Performance</a>
                        </li>
                        <li :class="[{ 'active': charttab == 2 }]">
                            <a class="" href="javascript:void(0)" @click="changeChartTab(2)">Trading</a>
                        </li>
                        <li :class="[{ 'active': charttab == 3 }]">
                            <a class="" href="javascript:void(0)" @click="changeChartTab(3)">DrawDown</a>
                        </li>
                        <li :class="[{ 'active': charttab == 4 }]">
                            <a class="" href="javascript:void(0)" @click="changeChartTab(4)">Slippage</a>
                        </li>
                        </ul>
                </div> 
                <!-- <select v-model="charttab" class="customdropdown" @change="changeChartTab($event)">
                    <option :value="key" v-for="item,key in tabJson" :key="key">{{item}}</option>
                </select> -->
            </div>
            <div class="position-relative" style="height:300px;">
                <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                <Performance v-if="charttab == 1" />
                <Trading v-if="charttab == 2" />
                <Drawdown v-if="charttab == 3" />
                <Slippage v-if="charttab == 4" />
            </div>
            <!-- <div  v-if="charttab != 4">
                <ul class="d-flex align-items-center justify-content-evenly">
                    <li v-for="list in dateDrop" :key="list.key">
                        <a class="f-14 gray selectDay" @click="dateDropSelected = list;changeRoute(list)" :class="[{ 'active': dateDropSelected.value == list.value }]">{{ list.value }}</a>
                    </li>
                </ul>
            </div> -->
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Performance from "@/components/charts/mobile-charts/trader-performance";
    import Trading from "@/components/charts/mobile-charts/trader-trading";
    import Drawdown from "@/components/charts/mobile-charts/trader-drawdown";
    import Slippage from "@/components/charts/mobile-charts/trader-slippage";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                charttab: 1,
                dateDrop: [
                    { key: 1, value: "1D" },
                    { key: 3, value: "3D" },
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 90, value: "3M" },
                    { key: 180, value: "6M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "Overall" },
                ],
                dateDropSelected: { key: 10000, value: "Overall" },
                tabJson : {
                    1 : 'Performance',
                    2 : 'Trading',
                    3  : 'DrawDown',
                    4 : 'Slippage'
                },
                selectChart : false

            };
        },
        components: {
            Performance,
            Trading,
            Drawdown,
            Slippage,
        },
        methods: {
            changeRoute(list){
               this.$router.replace({ query: { 't' : list.key , 'theme' : this.$route.query.theme } })
            },
            changeChartTab(e) {
                this.charttab = e;
                this.selectChart = false
                // this.$router.replace({ query: { 't' : this.$route.query.t , 'theme' : this.$route.query.theme, 'charttab' : this.tabJson[this.charttab]} })
                if(window.ReactNativeWebView) {
                    // send data object to React Native (only string)
                    window.ReactNativeWebView.postMessage(this.tabJson[this.charttab])
                }
            },
            
        },
        created() {
            if (this.$route.query.t) {
                this.dateDrop.forEach((val) => {
                    if (val.key == parseInt(this.$route.query.t)) {
                        this.dateDropSelected = val;
                    }
                });
            } else {
                this.dateDropSelected = { key: 10000, value: "Overall" };
            }
            if(this.$route.query?.charttab){
                for(const key in this.tabJson){
                    if(this.tabJson[key] == this.$route.query?.charttab){
                        this.charttab = parseInt(key)
                    }
                }
            }
            // this.calltraderDetail();
        },
    };
</script>
<style scoped> 
    
     
</style>