<template>
    
    <div id="slippageChart" class="slippage" style="margin-left:height:300px" v-show="Object.keys(store.tradingSlippageChart).length && store.tradingSlippageChart.series && store.tradingSlippageChart.series.length && store.tradingSlippageChart.series[0].data.length">------HELOOOO---------</div>
    <Nodata v-if="!store.singleLoading && Object.keys(store.tradingSlippageChart).length && store.tradingSlippageChart.series && store.tradingSlippageChart.series.length && store.tradingSlippageChart.series[0].data.length == 0"></Nodata>
</template>

<script>
// import * as am5 from "@amcharts/amcharts5";
import Highcharts from 'highcharts';
// import * as am5xy from "@amcharts/amcharts5/xy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {

        }
    },
    methods: {
        drawSlippageChart(){
            // let root = am5.Root.new("slippageChart", {
            //   useSafeResolution: false
            // });
            // root.interfaceColors.set("grid", am5.color(0xffffff));


            // // Set themes
            // root.setThemes([
            //     am5themes_Animated.new(root),
            //     // am5themes_Responsive.new(root),
            // ]);
            //   var chart = root.container.children.push(am5xy.XYChart.new(root, {
            //     focusable: true,
            //     panX: false,
            //     panY: false,
            //     wheelX: "panX",
            //     wheelY: "zoomX",
            //     pinchZoomX:true,
            //     draggable:false
            // }));

            // // Add cursor
            // var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            //     behavior: "none"
            // }));
            // cursor.lineY.set("visible", false);
            // cursor.lineX.set("visible", false);

            // var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10,
            //     startLocation: 1,
            //     endLocation: 1});
            
            // xRenderer.labels.template.setAll({
            //   rotation: -70,
            //   centerY: am5.p50,
            //   centerX: am5.p100,
            //   paddingRight: 15
            // });
            // var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            //   maxDeviation: 0,
            //   categoryField: "x",
            //   renderer:  xRenderer,

            //   // tooltip: am5.Tooltip.new(root, {})
            // }));
            // // var label2 = am5.Label.new(root, {
            // //     text: "[#666] Broker [/]",
            // //     x: am5.p50,
            // //     centerY: am5.p50,
            // //     fontSize: 12,
            // // })
            // // xAxis.children.push( label2 );


            // var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            //   maxDeviation: 0.3,
            //   renderer: am5xy.AxisRendererY.new(root, {})
            // }));
            // // var label1 = am5.Label.new(root, {
            // //     rotation: -90,
            // //     y: am5.p50,
            // //     text: "[#666] Pips[/]",
            // //     centerX: am5.p50,
            // //     fontSize: 12,
            // // })
            // // yAxis.children.unshift( label1 );


            //  let yRenderer = yAxis.get("renderer");
            // yRenderer.grid.template.setAll({
            //     stroke: am5.color(0x666666),
            //     strokeWidth: 1
            // });

            //  var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
            //     x: am5.percent(50),
            //     centerX: am5.percent(50),
            //     layout: root.horizontalLayout,
            //     useDefaultMarker: true,
            //     // paddingTop: 15,
            // }));
            // chart.get("colors").set("colors", [
            //     am5.color(0x4572a7),
            // ]);
            // legend.labels.template.setAll({
            //     fontSize: 12,
            //     fontWeight: "600"
            // });
            // legend.markerRectangles.template.setAll({
            //     cornerRadiusTL: 20,
            //     cornerRadiusTR: 20,
            //     cornerRadiusBL: 20,
            //     cornerRadiusBR: 20
            // });
            // legend.markers.template.setAll({
            //     width: 13,
            //     height: 13
            // });

            // // Create series
            
            // var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            //     //legendLabelText: 'Pips/Broker',
            //   name: "Series 1",
            //   xAxis: xAxis,
            //   yAxis: yAxis,
            //   valueYField: "y",
            //   sequencedInterpolation: true,
            //   categoryXField: "x",
            //   min : 0,
            //   tooltip: am5.Tooltip.new(root, {
            //     labelText:"[bold]{categoryX}[/] : {valueY}"
            //   })
            // }));

            // series.columns.template.setAll({  width: am5.percent(50),});
            // xAxis.get("renderer").labels.template.setAll({
            //     fill: root.interfaceColors.set("fill", am5.color("#666666")),
            //     fontSize: 12,
            // });
            // yAxis.get("renderer").labels.template.setAll({
            //     fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
            //     fontSize: 12,
            // });


            // // Set data
            // var data = this.store.tradingSlippageChart.series[0].data;
            // xAxis.data.setAll(data);
            // //legend.data.push(series);
            // series.data.setAll(data);
            // // Make stuff animate on load
            // series.appear(1000);
            // chart.appear(1000, 100);

            let chartOptions = {
                title: {
                    text:''
                },
                chart: {
                    type: 'column',
                    // spacingLeft: 0,
                    // spacingRight: 0
                    backgroundColor: 'var(--bodycolor)'

                    // --secondary
                    // spacing : [0, -6, 0, -6],
                },
                credits:{
                    enabled: false,
                },
                tooltip: {
                    pointFormat: '{series.name} {point.y:.2f}'
                },
                yAxis:{
                    threshold: null,
                    title: {
                        text: "",
                        style:{
                            color: 'var(--dark)',
                        }
                    },
                    
                    labels:{
                        style: {
                           color: 'var(--dark)',
                        }
                    },
                    format: '{point.y:.2f}',
                    // visible:false,
                    lineWidth: 0,
                    gridLineColor: "transparent",
                    // categories:[]
                    // lineColor: "transparent"
                },
                xAxis: {
                    labels:{
                        // format: '{value:%b %y}',
                        style: {
                            textOverflow: 'none',
                            fontWeight: 'medium',
                            color: 'var(--dark)',
                            // fontSize: '20px',
                        }
                    },
                    tickColor: "var(--bodycolor)",
                    
                    gridLineColor: "var(--bodycolor)",
                    lineColor: "var(--bodycolor)",
                    categories:[]
                    // visible:false,
                    // showFirstLabel: false,
                    // showLastLabel: false,
                    // type: 'categories',
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            // formatter: '{point.y:.2f}'
                        }
                    },
                    zoneAxis: 'y'

                },
                series: []
            };
            
            this.store.tradingSlippageChart.series.forEach((val,index) =>{
                if(val.data.length){
                    var db = []
                    for (var i = val.data.length - 1; i >= 0; i--) {
                        db.push(parseFloat(val.data[i].y))
                        chartOptions.xAxis.categories.push(val.data[i].x)
                    }
                    let temp = {
                        selected: false,
                        showInLegend: false,
                        name: 'Slippage',
                        type: 'column',
                        data: db,
                        color: index==0?'var(--blue)':'var(--secondary)',
                        borderWidth: 0
                    }
                    chartOptions.series.push(temp);
                    
                }
            })
            
            Highcharts.setOptions({
                lang: {
                    decimalPoint: ".",
                    thousandsSep: ",",
                    decimals: 2
                }
            });

            Highcharts.chart('slippageChart', chartOptions);
        },
        getTrendingSlippageChartData(){
            let formData  = {};
            this.store.getTrendingSlippageChartData(formData,true,this.$route.params.id).then( () =>{
                if(Object.keys(this.store.tradingSlippageChart).length && this.store.tradingSlippageChart.series && this.store.tradingSlippageChart.series.length > 0)

                    // console.log("this.store.tradingSlippageChart.series======>", this.store.tradingSlippageChart.series)
                    this.drawSlippageChart()
                //     am5.ready(() => {
                //     this.drawSlippageChart()
                // });
            })
        }
    },
    mounted() {
        this.getTrendingSlippageChartData()
    },
}
</script>
<style>
    .slippage{
        height: 300px;
        width: 100%;
    }
</style>